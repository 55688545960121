/**
 * @module Country
 */
export default class Country {
  /**
   * Create a Country
   * @param {string|null} id
   * @param {string|null} name
   * @param {string} iso
   * @param {string} iso3
   * @param {string} continent
   * @param {Array<string>|null} languages
   * @param {string|null} mainLanguage
   */
  constructor(id, name = null, iso = null, iso3 = null, continent = null, languages = null, mainLanguage = null) {
    this.id = id;
    this.name = name;
    this.iso = iso;
    this.iso3 = iso3;
    this.continent = continent;
    this.languages = languages;
    this.mainLanguage = mainLanguage;
  }

  static create(entity) {
    const { name, iso, iso3, continent, languages, mainLanguage } = entity.attributes;

    return new Country(entity.id, name, iso, iso3, continent, languages, mainLanguage);
  }
}
