import { PERCENTAGE_VALUES } from '@/model/modules/ecommerce/offer/fraud/PercentageOptions';

/**
 *  ControlApps
 *
 * @class ControlApps
 * @public
 * @constructor
 */
export default class ControlApps {
  /**
   * @typedef {object} ControlApps
   * @property @param {SelectOption} bannedAppsPercentage
   * @property @param {SelectOption} suspiciousAppsPercentage
   * @property @param {Boolean} bannedApps
   * @property @param {Boolean} suspiciousApps
   */
  constructor(bannedAppsPercentage = 0, suspiciousAppsPercentage = 0, bannedApps = false, suspiciousApps = false) {
    this.bannedAppsPercentage = PERCENTAGE_VALUES.find(value =>
      bannedAppsPercentage ? value.id === bannedAppsPercentage : true
    );
    this.suspiciousAppsPercentage = PERCENTAGE_VALUES.find(value =>
      suspiciousAppsPercentage ? value.id === suspiciousAppsPercentage : true
    );
    this.bannedApps = bannedApps;
    this.suspiciousApps = suspiciousApps;
  }

  payload() {
    return {
      bannedAppsPercentage: this.bannedAppsPercentage?.id || 0,
      suspiciousAppsPercentage: this.suspiciousAppsPercentage?.id || 0,
      bannedApps: this.bannedApps,
      suspiciousApps: this.suspiciousApps,
    };
  }
}
