export default class OfferPublisher {
  /*
   * TODO: refactor!! ourCommission? publisherPrice? as strings? OfferPublisher should be an entity linked to an offer
   * and a publisher, and the related properties
   */
  constructor(
    id = null,
    commissionValue = null,
    commissionType = null,
    commissionOwner = null,
    ourCommission = null,
    publisherPrice = null,
    publisherId = null,
    status = 'ACTIVE',
    directLink = null,
    impressionCode = null,
    test = null,
    subPublishersLocked = null
  ) {
    this.id = id;
    this.commissionValue = commissionValue;
    this.commissionType = commissionType;
    this.commissionOwner = commissionOwner;
    this.ourCommission = ourCommission;
    this.publisherPrice = publisherPrice;
    this.publisherId = publisherId;
    this.status = status;
    this.directLink = directLink;
    this.impressionCode = impressionCode;
    this.test = test;
    this.subPublishersLocked = subPublishersLocked;
  }

  payload() {
    const { publisherId, commissionValue, commissionOwner, commissionType, status, test, subPublishersLocked } = this;
    return {
      publisherId,
      commissionValue,
      commissionOwner,
      commissionType,
      status,
      test,
      subPublishers: { blackList: subPublishersLocked },
    };
  }

  static create(entity) {
    // TODO: parse status from included
    return new OfferPublisher(
      entity.id,
      entity.attributes.commission,
      entity.attributes.commissionType,
      entity.attributes.commissionOwner,
      entity.attributes.ourCommission,
      entity.attributes.publisherPrice,
      entity.relationships.publisher.data.id,
      entity.attributes.status,
      entity.attributes.directLink,
      entity.attributes.impressionCode,
      entity.attributes.test || false,
      entity.attributes.subPublishers?.blackList || []
    );
  }
}
