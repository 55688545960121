/**
 * InfoEvent
 *
 * @class
 * @public
 */
export default class InfoEventCollection {
  constructor(id, clickSessions, headers) {
    this.id = id;
    this.clickSessions = clickSessions;
    this.headers = headers;
  }

  static create(event) {
    const headers = event.attributes.headers;
    const attr = event.attributes.values;
    const clickSessions = [];
    Object.entries(attr).forEach(session => {
      const [key, value] = session;
      const clicks = [];
      const conversions = [];
      const impressions = [];
      const postEvents = [];

      value.clicks.forEach(click => {
        clicks.push(click);
      });
      value.conversions.forEach(conversion => {
        conversions.push(conversion);
      });
      value.impressions.forEach(impression => {
        impressions.push(impression);
      });
      value.postEvents.forEach(postEvent => {
        postEvents.push(postEvent);
      });

      clickSessions.push({
        id: key,
        status: value.status,
        clicks,
        conversions,
        impressions,
        postEvents,
      });
    });
    return new InfoEventCollection(event.id, clickSessions, headers);
  }
}
