import { PERCENTAGE_VALUES } from '@/model/modules/ecommerce/offer/fraud/PercentageOptions';
import { IP_MISSMATCH_OPTIONS } from '@/entities/ecommerce/fraud/IpMissMatch';
import { PROXY_USAGE_TYPE_OPTIONS } from '@/entities/ecommerce/fraud/ProxyUsageType';

export default {
  sourcesList: [
    { id: 'Facebook', name: 'Facebook' },
    { id: 'Instagram', name: 'Instagram' },
    { id: 'Twitter', name: 'Twitter' },
    { id: 'Snapchat', name: 'Snapchat' },
    { id: 'Pinterest', name: 'Pinterest' },
    { id: 'Google', name: 'Google' },
    { id: 'Youtube', name: 'Youtube' },
    { id: 'Tiktok', name: 'Tiktok' },
  ],
};

export const IP_USAGE_TYPES = [
  { name: 'None', id: 'NONE' },
  { name: 'Only ISP, MOB, ISP/MOB', id: 'ONLY_ISP_MOB' },
  { name: 'All but DCH, SES, RSV', id: 'ALL_EXCEPT_DCH_SES_RSV' },
];

export const PERCENTAGE_OPTIONS = PERCENTAGE_VALUES;

export const IP_MISSMATCH_OPTIONS_LIST = [IP_MISSMATCH_OPTIONS.NONE, IP_MISSMATCH_OPTIONS.COUNTRY];

export const PROXY_USAGE_TYPE_OPTIONS_LIST = [
  PROXY_USAGE_TYPE_OPTIONS.NONE,
  PROXY_USAGE_TYPE_OPTIONS.ALL_EXCEPT_VPN_TOR_DCH_SES_RES,
  PROXY_USAGE_TYPE_OPTIONS.NOT_PROXIES,
];
