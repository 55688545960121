/// <reference path="./Advertiser.js" />
/// <reference path="./Carrier.js" />
/// <reference path="../Core/Country.js" />
/// <reference path="./Tag.js" />

import * as RESOURCE from '@/services/keys';
import Country from '@/entities/Core/Country';
import COST_VALUE_TYPES from '@/model/modules/ecommerce/offer/costValueTypes';
import Capping from '@/entities/ecommerce/Capping';
import Utms from '@/entities/ecommerce/Utms';
import Fraud from '@/entities/ecommerce/fraud/Fraud';
import IncludeItem from '@/model/shared/IncludeItem';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import { dateToYYYYMMDD, stringToDate } from '@/filters/dateFilters';
import getCurrentUTC from '@/utils/dateTime/getCurrentUTC';
import getDateOnUTCOffset from '@/utils/dateTime/getDateOnUTCOffset';
import getDateWithoutUTCOffset from '@/utils/dateTime/getDateWithoutUTCOffset';
import OfferPostEvent from '@/entities/ecommerce/OfferPostEvent';
import OfferAttribution from '@/entities/ecommerce/OfferAttribution';

/**
 * Offer
 *
 * @class
 * @public
 */

export default class Offer {
  /**
   * @constructor
   * @typedef {object} Offer
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} status
   * @property @param {string} createdAt
   * @property @param {Country} country
   * @property @param {string} priceType Payment Method,
   * @property @param {number} netPriceValue
   * @property @param {number} priceValueType
   * @property @param {Advertiser} advertiser
   * @property @param {string} url - https://example.com?payout=$MONEY&pixel=$PIXEL"
   * @property @param {Tag[]} tags
   * @property @param {object} offerAttribution
   * @property @param {boolean} offerAttribution.postclick_active
   * @property @param {number} offerAttribution.postclick_value
   * @property @param {boolean} offerAttribution.postview_active
   * @property @param {number} offerAttribution.postview_value
   * @property @param {number} costPercentage
   * @property @param {object} currency
   * @property @param {Capping} cap
   * @property @param {IncludeItem|null} offerCountry
   * @property @param {OfferComment[]} comments
   * @property @param {OfferPostEvent[]} postEvents
   * @property @param {Fraud} offerFraud
   * @property @param {Array<String>} bundleBlackList
   * @property @param {Array<String>} domainBlackList
   * @property @param {Object} creatives
   * @property @param {Date} start
   * @property @param {Date} end
   * @property @param {string} utc
   * @property @param {Array<Currency>} currencies
   * @property @param {number} totalLeads
   * @property @param {Boolean} applyTechFee
   * @property @param {Boolean} postEventStatus
   * @property @param {string} pixel
   * @property @param {Campaign} campaign
   * @property @param {Utms} utms
   * @property @param {number} uniqueUserDailyCapping
   * @property @param {number} grossPriceValue
   * @property @param {number} uniqueConversion
   */
  constructor(
    id = null,
    name = '',
    status = null,
    createdAt = null,
    country = null,
    priceType = null,
    netPriceValue = null,
    priceValueType = COST_VALUE_TYPES.PRICE,
    advertiser = null,
    url = null,
    carrier = null,
    tags = [],
    offerAttribution = new OfferAttribution(),
    currency = null,
    capping = new Capping(),
    offerCountry = new IncludeItem('offerCountry', 'Countries'),
    comments = [],
    publishers = [],
    postEvents = [],
    offerFraud = new Fraud(),
    bundleBlackList = [],
    domainBlackList = [],
    creatives = null,
    start = null,
    end = null,
    utc = null,
    currencies = [],
    totalLeads = 0,
    applyTechFee = true,
    postEventStatus = false,
    pixel = null,
    campaign = null,
    pixelType = null,
    utms = new Utms(),
    uniqueUserDailyCapping = 0,
    grossPriceValue = null,
    uniqueConversion = 0,
    fraudTemplate = null
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.createdAt = createdAt;
    /** @type Country */
    this.country = country;
    this.priceType = priceType;
    this.netPriceValue = netPriceValue;
    this.priceValueType = priceValueType;
    this.advertiser = advertiser;
    this.url = url;
    this.carrier = carrier;
    this.tags = tags;
    this.offerAttribution = offerAttribution;
    this.currency = currency;
    this.cap = capping;
    this.offerCountry = offerCountry;
    this.comments = comments;
    this.publishers = publishers;
    this.postEvents = postEvents;
    this.offerFraud = offerFraud;
    this.bundleBlackList = bundleBlackList;
    this.domainBlackList = domainBlackList;
    this.creatives = creatives;
    this.start = start || new Date();
    this.end = end;
    this.utc = utc || getCurrentUTC();
    this.currencies = currencies;
    this.totalLeads = totalLeads;
    this.applyTechFee = applyTechFee;
    this.postEventStatus = postEventStatus;
    this.pixel = pixel;
    this.campaign = campaign;
    this.pixelType = pixelType;
    this.utms = utms;
    this.uniqueUserDailyCapping = uniqueUserDailyCapping;
    this.grossPriceValue = grossPriceValue;
    this.uniqueConversion = uniqueConversion;
    this.fraudTemplate = fraudTemplate;
  }

  /*
   * Country symbol
   */
  countryIso() {
    return this.country?.iso3;
  }

  payload() {
    const { country, tags, advertiser, currency, end, start, utc, campaign, ...params } = this;
    // offerCountry needs a values attribute instead of value
    params.offerCountry.values = params?.offerCountry?.payload() || [];

    const startNoUtc = getDateWithoutUTCOffset(start, utc);
    const endNoUtc = getDateWithoutUTCOffset(end, utc);

    return {
      ...params,
      tagNames: tags?.map(tag => tag.name) || [],
      advertiserId: advertiser?.id,
      countryIso: country?.id,
      currencyId: currency?.id || 'EUR',
      date: {
        start: dateToYYYYMMDD(startNoUtc, true),
        end: end ? dateToYYYYMMDD(endNoUtc, true) : null,
        utc,
      },
      campaignId: campaign?.id,
    };
  }

  clonePayload() {
    const {
      name,
      status,
      url,
      bundleBlackList,
      domainBlackList,
      priceType,
      netPriceValue,
      priceValueType,
      cap,
      country,
      tags,
      advertiser,
      currency,
      end,
      start,
      utc,
      campaign,
      offerFraud,
      offerAttribution,
      applyTechFee,
      pixelType,
      postEvents,
      uniqueUserDailyCapping,
      postEventStatus,
      grossPriceValue,
      uniqueConversion,
      fraudTemplate,
      ...params
    } = this;
    // offerCountry needs a values attribute instead of value
    params.offerCountry.values = params?.offerCountry?.payload() || [];

    const startNoUtc = getDateWithoutUTCOffset(start, utc);
    const endNoUtc = getDateWithoutUTCOffset(end, utc);
    const fraud = offerFraud.payload();
    const postEventsFormated = postEvents.map(postEvent => {
      return postEvent.payload();
    });
    return {
      ...fraud,
      name,
      advertiserId: advertiser?.id,
      campaignId: campaign?.id,
      currencyId: currency?.id || 'EUR',
      status,
      countryIso: country?.id,
      url,
      priceType,
      netPriceValue,
      priceValueType,
      cap,
      fraudOfferId: offerFraud.id,
      tagNames: tags?.map(tag => tag.name) || [],
      bundleBlackList,
      domainBlackList,
      offerAttribution,
      date: {
        start: dateToYYYYMMDD(startNoUtc, true),
        end: end ? dateToYYYYMMDD(endNoUtc, true) : null,
        utc,
      },
      blockListIds: [],
      applyTechFee,
      pixelType,
      postEvents: postEventsFormated,
      uniqueUserDailyCapping,
      postEventStatus,
      grossPriceValue,
      uniqueConversion,
      fraudTemplate,
    };
  }

  /**
   *
   * @param entity
   * @param included
   * @return {Offer}
   */
  static create(entity, included = {}) {
    const advertiser = included?.[RESOURCE.ADVERTISER_RESOURCE]?.find(
      adv => adv.id === entity?.relationships?.advertiser.data.id
    );
    const postEventTemplates = included?.[RESOURCE.POST_EVENT_RESOURCE];
    const postEvents = included?.[RESOURCE.OFFER_POST_EVENT_RESOURCE]?.map(event => {
      const templateId = event.relationships?.offerPostEvent.data.id;
      const template = postEventTemplates?.find(eventTemplate => eventTemplate.id === templateId);
      return new OfferPostEvent(
        event.id,
        event.unique,
        { value: event.netPrice },
        { value: event.grossPrice },
        event.code,
        template
      );
    });
    const tags = included?.[RESOURCE.TAGS_RESOURCE]?.filter(tag =>
      entity?.relationships?.linkedTags.data.filter(t => t.id === tag?.id)
    );
    const commentsOnEntry = entity?.relationships?.offerCommentCollection?.data;
    const comments = included?.[RESOURCE.OFFER_COMMENTS_RESOURCE]?.filter(
      ({ id }) => !!commentsOnEntry.find(c => id === c.id)
    );
    if (comments) {
      comments.forEach(comment => {
        comment.author = included?.author?.find(ath => ath.id === comment.author.id);
      });
    }
    const offerFraud = included?.[RESOURCE.OFFER_FRAUD_RESOURCE]?.find(
      fraud => fraud.id === entity?.relationships?.offerFraud?.data?.id
    );
    const offerPublishers = entity?.relationships?.offerPublisherCollection?.data;
    const publishers = [];
    if (offerPublishers) {
      offerPublishers.forEach(publisher => {
        const publisherId = publisher.id;
        const offerPublishersAsociated = included?.[RESOURCE.OFFER_PUBLISHER_RESOURCE].find(
          pub => pub.id === publisherId
        );
        offerPublishersAsociated.publisher = included?.[RESOURCE.PUBLISHER_RESOURCE].find(
          pub => pub.id === offerPublishersAsociated.publisherId
        );
        publishers.push(offerPublishersAsociated);
      });
    }
    let currencies = [];
    let currency = {};
    if (included?.[RESOURCE.CURRENCY_RESOURCE]) {
      currencies =
        included[RESOURCE.CURRENCY_RESOURCE].filter(curr => entity?.relationships?.currency.data.id === curr.id) || [];
      currency =
        included[RESOURCE.CURRENCY_RESOURCE].find(curr => entity?.relationships?.currency.data.id === curr.id) || {};
    }

    const creatives = {};
    creatives[CREATIVE_TYPES.BANNER.id] = included?.[RESOURCE.CREATIVE_BANNER_RESOURCE];
    creatives[CREATIVE_TYPES.HTML.id] = included?.[RESOURCE.CREATIVE_HTML_RESOURCE];
    creatives[CREATIVE_TYPES.DEEPLINK.id] = included?.[RESOURCE.CREATIVE_DEEPLINK_RESOURCE];
    creatives[CREATIVE_TYPES.TEXTLINK.id] = included?.[RESOURCE.CREATIVE_TEXTLINK_RESOURCE];
    creatives[CREATIVE_TYPES.FEED.id] = included?.[RESOURCE.CREATIVE_FEED_RESOURCE];
    const utc = entity.attributes.utc;
    const start = entity.attributes.start && getDateOnUTCOffset(entity.attributes.start, utc);
    const end = entity.attributes.end ? getDateOnUTCOffset(entity.attributes.end, utc) : null;
    const campaign = included?.[RESOURCE.CAMPAIGNS_RESOURCE]?.find(
      campaign => campaign.id === entity?.relationships?.campaign.data.id
    );
    let offerAttribution;
    if (entity.attributes.offerAttribution) {
      const {
        postclick_active,
        postclick_value,
        postview_active,
        postview_value,
        minAttributionTime,
        maxAttributionTime,
      } = entity.attributes.offerAttribution;
      offerAttribution = new OfferAttribution(
        postclick_active,
        postclick_value,
        postview_active,
        postview_value,
        minAttributionTime,
        maxAttributionTime
      );
    } else {
      offerAttribution = new OfferAttribution();
    }
    const fraudTemplate = included?.[RESOURCE.TEMPLATE_RESOURCE]?.[0];
    return new Offer(
      entity.id,
      entity.attributes.name,
      entity.attributes.status,
      stringToDate(entity.attributes.createdAt),
      new Country('', '', entity.attributes.countryIso),
      entity.attributes.priceType,
      entity.attributes.netPriceValue,
      entity.attributes.priceValueType,
      advertiser,
      entity.attributes.url,
      entity.attributes.carrier,
      tags,
      offerAttribution,
      currency,
      entity.attributes.cap,
      Offer.offerCountryToIncludeItem(entity.attributes?.offerCountry),
      comments,
      publishers,
      postEvents,
      offerFraud,
      entity.attributes?.bundleBlackList,
      entity.attributes?.domainBlackList,
      creatives,
      start,
      end,
      utc,
      currencies,
      entity.attributes.totalLeads,
      entity.attributes.applyTechFee,
      entity.attributes.postEventStatus,
      entity.attributes.pixel,
      campaign,
      entity.attributes.pixelType,
      entity.attributes.utms,
      entity.attributes.uniqueUserDailyCapping,
      entity.attributes.grossPriceValue,
      entity.attributes.uniqueConversion || 0,
      fraudTemplate
    );
  }

  static offerCountryToIncludeItem(offerCountry) {
    const values = offerCountry?.values.map(value => ({ id: value, name: '' }));
    return new IncludeItem('offerCountry', 'Countries', offerCountry?.type, values);
  }
}
