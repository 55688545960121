import Creative from '@/model/modules/ecommerce/offer/creative/Creative';
import CreativeTypes from '@/model/modules/ecommerce/offer/creative/CreativeTypes';

/**
 *  Creative
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeBanner extends Creative {
  /**
   * @param {String} id
   * @param {Publisher} publisher
   * @param {String} name
   * @param {String} path
   * @param {String} tagsTemplate
   * @param {String} url
   * @param {String} width
   * @param {String} height
   */

  constructor(id = null, publisher = null, name = '', path = '', tagsTemplate = '', url = '', width = '', height = '') {
    super(id, CreativeTypes.BANNER, publisher);
    this.name = name;
    this.path = path;
    this.tagsTemplate = tagsTemplate;
    this.url = url;
    this.width = width;
    this.height = height;
  }

  /**
   *
   * @param entity
   * @return {CreativeBanner}
   */
  static create(entity) {
    const creative = entity.attributes;
    return new CreativeBanner(
      entity.id,
      creative.publisher,
      creative.name,
      creative.path,
      creative.tagsTemplate,
      creative.url
    );
  }

  clone() {
    return new CreativeBanner(
      this.id,
      this.publisher,
      this.name,
      this.path,
      this.tagsTemplate,
      this.url,
      this.width,
      this.height
    );
  }
}
