import CreativeHtmlSize from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtmlSize';

const CREATIVE_HTML_SIZES = {
  '300x250': new CreativeHtmlSize('300x250', '300x250'),
  '300x300': new CreativeHtmlSize('300x300', '300x300'),
  '300x600': new CreativeHtmlSize('300x600', '300x600'),
  '468x60': new CreativeHtmlSize('468x60', '468x60'),
  '234x60': new CreativeHtmlSize('234x60', '234x60'),
  '234x90': new CreativeHtmlSize('234x90', '234x90'),
  '728x90': new CreativeHtmlSize('728x90', '728x90'),
  '900x90': new CreativeHtmlSize('900x90', '900x90'),
  '120X600': new CreativeHtmlSize('120X600', '120x600'),
  EMAIL: new CreativeHtmlSize('EMAIL', 'Email'),
  '1:1': new CreativeHtmlSize('1:1', '1:1'),
  PAGINA_COMPLETA: new CreativeHtmlSize('PAGINA_COMPLETA', 'Complete page'),
};

export default CREATIVE_HTML_SIZES;
