import ASN from './ASN';
import IpControl from './IpControl';
import Referer from './Referer';
import UsageType from './UsageType';
import ControlApps from './ControlApps';
import ControlUserAgents from './ControlUserAgents';
import { IP_USAGE_TYPES } from '@/components/organisms/modules/ecommerce/offer/form/fraud/config';
import ProxyUsageType from './ProxyUsageType';
import IpMissMatch from './IpMissMatch';
import ControlLanguage from './ControlLanguage';

/**
 *  Fraud
 *
 * @class Fraud
 * @public
 * @constructor
 */
export default class Fraud {
  /**
   * @typedef {object} Fraud
   * @property @param {string} id
   * @property @param {IpControl} ipControl
   * @property @param {ASN} asn
   * @property @param {Referer} referer
   * @property @param {ControlApps} controlApps
   * @property @param {ControlUserAgents} controlUserAgents
   * @property @param {ProxyUsageType} proxyUsageType
   * @property @param {IpMissMatch} ipMissMatch
   * @property @param {ControlLanguage} controlLanguage
   */
  constructor(
    id = null,
    ipControl = new IpControl(),
    asn = new ASN(),
    referer = new Referer(),
    usageType = new UsageType(),
    controlApps = new ControlApps(),
    controlUserAgents = new ControlUserAgents(),
    proxyUsageType = new ProxyUsageType(),
    ipMissMatch = new IpMissMatch(),
    controlLanguage = new ControlLanguage(),
    templateId = null
  ) {
    this.id = id;
    this.ipControl = ipControl;
    this.asn = asn;
    this.referer = referer;
    this.usageType = usageType;
    this.controlApps = controlApps;
    this.controlUserAgents = controlUserAgents;
    this.proxyUsageType = proxyUsageType;
    this.ipMissMatch = ipMissMatch;
    this.controlLanguage = controlLanguage;
    this.templateId = templateId;
  }

  clone() {
    return new Fraud(
      this.id,
      this.ipControl.clone(),
      this.asn.clone(),
      this.referer.clone(),
      this.usageType,
      this.controlApps,
      this.controlUserAgents,
      this.proxyUsageType,
      this.ipMissMatch,
      this.controlLanguage,
      this.templateId
    );
  }

  payload() {
    const {
      ipControl,
      asn,
      referer,
      usageType,
      controlApps,
      controlUserAgents,
      proxyUsageType,
      ipMissMatch,
      controlLanguage,
      templateId,
    } = this;

    return {
      ipControl: ipControl.payload(),
      asn: asn.payload(),
      referer: referer.payload(),
      usageType: usageType.payload(),
      controlApps: controlApps.payload(),
      controlUserAgents: controlUserAgents.payload(),
      proxyUsageType: proxyUsageType.payload(),
      ipMissMatch: ipMissMatch.payload(),
      controlLanguage: controlLanguage.payload(),
      templateId,
    };
  }

  /**
   *
   * @param entity
   * @param included
   * @return {Fraud}
   */
  static create(entity) {
    const { usageType, proxyUsageType, ipMissMatch, controlLanguage } = entity.attributes;
    const utClick = IP_USAGE_TYPES.find(type => type.id === usageType.click);
    const utLeadClick = IP_USAGE_TYPES.find(type => type.id === usageType.lead_click);
    const utLeadConversion = IP_USAGE_TYPES.find(type => type.id === usageType.lead_conversion);
    const { repeatedIps, maxLeadsPerIP, blockRepeatedIps } = entity.attributes.ipControl;
    const { includes, excludes } = entity.attributes.asn;
    const { traficIDFA, traficDomainBundle, traficPublisher, blockTraficApp, includesSources } =
      entity.attributes.referer;
    const { bannedAppsPercentage, suspiciousAppsPercentage, bannedApps, suspiciousApps } = Array.isArray(
      entity.attributes.controlApps
    )
      ? new ControlApps()
      : entity.attributes.controlApps;
    const { bannedUserAgentsPercentage, suspiciousUserAgentsPercentage, bannedUserAgents, suspiciousUserAgents } =
      Array.isArray(entity.attributes.controlUserAgents)
        ? new ControlUserAgents()
        : entity.attributes.controlUserAgents;
    return new Fraud(
      entity.id,
      new IpControl(repeatedIps, maxLeadsPerIP, blockRepeatedIps),
      new ASN(includes, excludes),
      new Referer(traficIDFA, traficDomainBundle, traficPublisher, blockTraficApp, includesSources),
      new UsageType(utClick, utLeadClick, utLeadConversion),
      new ControlApps(bannedAppsPercentage, suspiciousAppsPercentage, bannedApps, suspiciousApps),
      new ControlUserAgents(
        bannedUserAgentsPercentage,
        suspiciousUserAgentsPercentage,
        bannedUserAgents,
        suspiciousUserAgents
      ),
      new ProxyUsageType(proxyUsageType.lead_click, proxyUsageType.lead_conversion),
      new IpMissMatch(ipMissMatch),
      new ControlLanguage(controlLanguage.blockWrongLanguage),
      entity.attributes.templateId
    );
  }
}
