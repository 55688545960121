// @ts-check

export default class ValidAndInvalidObject {
  /**
   * @param {number} accumulated
   * @param {number} valid
   * @param {number} blocked
   */
  constructor(accumulated, valid, blocked) {
    this.accumulated = accumulated;
    this.valid = valid;
    this.blocked = blocked;
  }
}
