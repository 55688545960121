/**
 *  CreativeType
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeType {
  /**
   * @param {String} id
   * @param {String} name
   * @param {String} value
   */

  constructor(id = null, name = null, value = null) {
    this.id = id;
    this.name = name;
    this.value = value;
  }
}
