import { stringToDate } from '@/filters/dateFilters';

export default function (date, utc) {
  date = stringToDate(date);
  const hours = utc.split(':')[0];
  const sign = hours.charAt(0);

  if (sign !== '0') {
    const utcHours = parseInt(hours.slice(1));
    const hourSum = sign === '-' ? -1 : 1;

    date.setHours(date.getHours() + utcHours * hourSum);
  }

  return date;
}
