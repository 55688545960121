import PostEventTemplateType from '@/model/modules/ecommerce/PostEventTemplateType';
import POST_EVENT_TEMPLATE_TYPES from '@/model/modules/ecommerce/PostEventTemplateTypes';

/**
 *  PostEventTemplate
 *
 * @class
 * @public
 * @constructor
 */
export default class PostEventTemplate {
  /**
   * @param {String} id
   * @param {String} name
   * @param {Number} unique
   * @param {value, amount} netPrice
   * @param {String} pixel
   * @param {PostEventTemplateType} type
   * @param {date} createdAt
   * @param {number} offersCount
   * @param {value, amount} grossPrice
   */
  constructor(
    id = null,
    name = null,
    unique = 0,
    netPrice = null,
    pixel = '',
    type = POST_EVENT_TEMPLATE_TYPES.CUSTOM,
    createdAt = null,
    offersCount = 0,
    grossPrice = null
  ) {
    this.id = id;
    this.name = name;
    this.unique = unique;
    this.netPrice = netPrice;
    this.pixel = pixel;
    this.type = type;
    this.createdAt = createdAt;
    this.offersCount = offersCount;
    this.grossPrice = grossPrice;
  }

  payload() {
    const { unique, netPrice, name, type, grossPrice } = this;
    return {
      type: type.id,
      name,
      unique,
      netPrice: netPrice.value,
      grossPrice: grossPrice.value,
    };
  }

  /**
   *
   * @param entity
   * @return {PostEventTemplate}
   */
  static create(entity) {
    return new PostEventTemplate(
      entity.id,
      entity.attributes.name,
      entity.attributes.unique,
      { value: entity.attributes.netPrice },
      entity.attributes.pixel,
      new PostEventTemplateType(entity.attributes.type, entity.attributes.type),
      entity.createdAt,
      entity.attributes.offersCount,
      { value: entity.attributes.grossPrice }
    );
  }

  /**
   *
   * @return {PostEventTemplate}
   */
  clone() {
    return new PostEventTemplate(
      this.id,
      this.name,
      this.unique,
      this.netPrice,
      this.pixel,
      this.type,
      this.createdAt,
      this.offersCount,
      this.grossPrice
    );
  }
}
