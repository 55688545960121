import PostEvent from '@/entities/ecommerce/PostEvent';

/**
 *  OfferPostEvent
 *
 * @class
 * @public
 * @constructor
 */

/**
 * @typedef {Object} PostEventCode
 * @property {string} offerLink - this is a URL
 * @property {string} campaignLink - this is a URL
 */

export default class OfferPostEvent extends PostEvent {
  /**
   * @param {string} id
   * @param {boolean} unique
   * @param {{value: number}} netPrice
   * @param {{value: number}} grossPrice
   * @param {string} code - code ex-pixel
   * @param {import('./PostEventTemplate').default} template
   * @param {boolean} changed
   */
  constructor(id = null, unique = null, netPrice = null, grossPrice = null, code, template = null, changed = false) {
    super(id, unique, netPrice, grossPrice, code, template?.id);
    this.postEventTemplate = template;
    /** @type {PostEventCode|null} */
    this.code = null;
    this.parcePixelCode(code);
    this.changed = changed;
  }

  /**
   * Parse code form JSON
   * @param {string} stringCode
   * @return {PostEventCode|null}
   */
  parcePixelCode(stringCode = '') {
    // let emptyCode = { campaignLink: '', offerLink: '' };
    try {
      this.code = typeof stringCode === 'string' ? JSON.parse(stringCode) : null;
    } catch {
      this.code = null;
    }
  }

  /**
   * @param {{id:string,attributes:OfferPostEvent}} entity
   * @return {OfferPostEvent}
   */
  static create({ id, attributes }) {
    return new OfferPostEvent(
      id,
      attributes.unique,
      attributes.netPrice,
      attributes.grossPrice,
      attributes.pixel || attributes.code,
      null,
      false
    );
  }
}
