import ReportAuthor from './ReportAuthor';

export default class Report {
  constructor(
    id = null,
    createdAt = null,
    startDate = null,
    endDate = null,
    events = [],
    filters = {},
    status = null,
    author = new ReportAuthor()
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.startDate = startDate;
    this.endDate = endDate;
    this.events = events;
    this.filters = filters;
    this.status = status;
    this.author = author;
  }

  static create(entity, included) {
    const attr = entity.attributes;
    const author = included?.authors?.find(author => author.id === entity.relationships.author.data.id);
    const filters = attr.filters;
    const offerFilters = [];
    const advertiserFilters = [];
    const campaignFilters = [];
    const publisherFilters = [];
    const countryFilters = [];

    if (typeof filters.of === 'string') {
      offerFilters.push(included?.offers?.find(offerIncluded => offerIncluded.id === filters.of));
    } else {
      filters?.of?.forEach(offer => {
        const offerFound = included?.offers?.find(offerIncluded => offerIncluded.id === offer);
        offerFilters.push(offerFound);
      });
    }
    if (typeof filters.ad === 'string') {
      advertiserFilters.push(included?.advertisers?.find(advertiserIncluded => advertiserIncluded.id === filters.ad));
    } else {
      filters.ad?.forEach(advertiser => {
        const advertiserFound = included?.advertisers?.find(advertiserIncluded => advertiserIncluded.id === advertiser);
        advertiserFilters.push(advertiserFound);
      });
    }
    if (typeof filters.camp === 'string') {
      campaignFilters.push(included?.campaigns?.find(campaignIncluded => campaignIncluded.id === filters.camp));
    } else {
      filters.camp?.forEach(campaign => {
        const campaignFound = included?.campaigns?.find(campaignIncluded => campaignIncluded.id === campaign);
        campaignFilters.push(campaignFound);
      });
    }
    if (typeof filters.pb === 'string') {
      publisherFilters.push(included?.publishers?.find(publisherIncluded => publisherIncluded.id === filters.pb));
    } else {
      filters.pb?.forEach(publisher => {
        const publisherFound = included?.publishers?.find(publisherIncluded => publisherIncluded.id === publisher);
        publisherFilters.push(publisherFound);
      });
    }
    if (typeof filters.countryIso === 'string') {
      countryFilters.push(included?.countries?.find(countryIncluded => countryIncluded.id === filters.countryIso));
    } else {
      filters.countryIso?.forEach(country => {
        const countryFound = included?.countries?.find(countryIncluded => countryIncluded.id === country);
        countryFilters.push(countryFound);
      });
    }

    return new Report(
      entity.id,
      attr.createdAt,
      attr.startDate,
      attr.endDate,
      attr.events,
      {
        offers: offerFilters,
        advertisers: advertiserFilters,
        campaigns: campaignFilters,
        publishers: publisherFilters,
        countries: countryFilters,
      },
      attr.status,
      author
    );
  }
}
