/**
 *  PostEventTemplateType
 *
 * @class
 * @public
 * @constructor
 */

export default class PostEventTemplateType {
  /**
   * @param {String} id
   * @param {String} name
   */
  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }
}
