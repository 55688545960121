/**
 *  Utms
 *
 * @class
 * @public
 * @constructor
 */

export default class Utms {
  /**
   * @param {string} banner
   * @param {string} html
   * @param {string} deeplink
   * @param {string} textlink
   * @param {string} feed
   */
  constructor(banner = null, html = null, deeplink = null, textlink = null, feed = null) {
    this.banner = banner;
    this.html = html;
    this.deeplink = deeplink;
    this.textlink = textlink;
    this.feed = feed;
  }
}
