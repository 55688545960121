export const PERCENTAGE_VALUES = [
  { name: 'None', id: 0 },
  { name: '10%', id: 0.1 },
  { name: '20%', id: 0.2 },
  { name: '30%', id: 0.3 },
  { name: '40%', id: 0.4 },
  { name: '50%', id: 0.5 },
  { name: '60%', id: 0.6 },
  { name: '70%', id: 0.7 },
  { name: '80%', id: 0.8 },
  { name: '90%', id: 0.9 },
  { name: 'All', id: 1 },
];
