export default class ReportAuthor {
  constructor(id, name, email, role) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.role = role;
  }

  static create(entity) {
    return new ReportAuthor(entity.id, entity.attributes.name, entity.attributes.email, entity.attributes.role);
  }
}
