import CreativeType from '@/model/modules/ecommerce/offer/creative/CreativeType';

const CREATIVE_TYPES = {
  BANNER: new CreativeType('BANNER', 'Banner', 'banner'),
  HTML: new CreativeType('HTML', 'HTML', 'html'),
  DEEPLINK: new CreativeType('DEEPLINK', 'Deeplink', 'deeplink'),
  TEXTLINK: new CreativeType('TEXTLINK', 'Textlink', 'textlink'),
  FEED: new CreativeType('FEED', 'Feed', 'feed'),
};

export default CREATIVE_TYPES;
