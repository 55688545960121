export default function (date = new Date()) {
  let offset = (date.getTimezoneOffset() / 60) * -1; // we use -1 to get the inverse
  let symbol = '';
  if (offset > 0) {
    symbol = '+';
  }

  offset = offset.toString();
  offset = offset.padStart(2, '0');

  return `${symbol}${offset}:00`;
}
