/**
 *  ASNItem
 *
 * @class ASNItem
 * @public
 * @constructor
 */

export default class ASNItem {
  /**
   * @typedef {object} ASNItem
   */
  constructor(id = null, name = null, autonomousSystemNumber = null, autonomousSystemOrganization = null) {
    this.id = id;
    this.name = name || `${autonomousSystemNumber} ${autonomousSystemOrganization}`;
    this.autonomousSystemNumber = autonomousSystemNumber;
    this.autonomousSystemOrganization = autonomousSystemOrganization;
  }

  /**
   *
   * @param entity
   * @return {ASNItem}
   */
  static create(entity) {
    const { autonomousSystemNumber, autonomousSystemOrganization } = entity?.attributes;
    const name = `${autonomousSystemNumber} ${autonomousSystemOrganization}`;
    return new ASNItem(entity?.id, name, autonomousSystemNumber, autonomousSystemOrganization);
  }
}
