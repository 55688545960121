import SelectOption from '@/model/shared/SelectOption';

export const IP_MISSMATCH_OPTIONS = {
  NONE: new SelectOption('NONE', 'None'),
  COUNTRY: new SelectOption('COUNTRY', 'Country Missmatch'),
};

/**
 *  IpMissMatch
 *
 * @class IpMissMatch
 * @public
 * @constructor
 */
export default class IpMissMatch {
  /**
   * @typedef {object} ControlUserAgents
   * @property @param {string} IpMissMatch
   */
  constructor(ipMissMatch = IP_MISSMATCH_OPTIONS[0]) {
    this.value = IP_MISSMATCH_OPTIONS[ipMissMatch];
  }

  payload() {
    if (!this.value) return null;
    return this.value.id;
  }
}
