import Creative from '@/model/modules/ecommerce/offer/creative/Creative';
import CreativeTypes from '@/model/modules/ecommerce/offer/creative/CreativeTypes';

/**
 *  CreativeDeeplink
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeDeeplink extends Creative {
  /**
   * @param {String} id
   * @param {Publisher} publisher
   * @param {String} name
   * @param {String} targetUrl
   */

  constructor(id = null, publisher = null, name = null, targetUrl = null) {
    super(id, CreativeTypes.DEEPLINK, publisher);
    this.name = name;
    this.targetUrl = targetUrl;
  }

  /**
   *
   * @param entity
   * @return {CreativeDeeplink}
   */
  static create(entity) {
    const creative = entity.attributes;
    return new CreativeDeeplink(entity.id, creative.publisher, creative.name, creative.targetUrl);
  }

  payload() {
    const parent = super.payload();
    const { name, targetUrl } = this;
    const child = { name, targetUrl, utms: '' };

    return { ...parent, ...child };
  }

  clone() {
    return new CreativeDeeplink(this.id, this.publisher, this.name, this.targetUrl);
  }
}
