/**
 * UrlAlias
 *
 * @class UrlAlias
 * @public
 * @constructor
 */

export default class UrlAlias {
  /**
   * @typedef {object} UrlAlias
   * @property @param {string} id
   * @property @param {string} url
   * @property @param {string} type
   * @property @param {number} order
   */
  constructor(id, url, type, order) {
    this.id = id;
    this.url = url;
    this.type = type;
    this.order = order;
  }

  static create(entity) {
    const { url, type, order } = entity.attributes;
    return new UrlAlias(entity.id, url, type, order);
  }
}
