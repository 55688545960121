import { PERCENTAGE_VALUES } from '@/model/modules/ecommerce/offer/fraud/PercentageOptions';

/**
 *  ControlUserAgents
 *
 * @class ControlUserAgents
 * @public
 * @constructor
 */
export default class ControlUserAgents {
  /**
   * @typedef {object} ControlUserAgents
   * @property @param {SelectOption} bannedUserAgentsPercentage
   * @property @param {SelectOption} suspiciousUserAgentsPercentage
   * @property @param {Boolean} bannedUserAgents
   * @property @param {Boolean} suspiciousUserAgents
   */
  constructor(
    bannedUserAgentsPercentage = 0,
    suspiciousUserAgentsPercentage = 0,
    bannedUserAgents = false,
    suspiciousUserAgents = false
  ) {
    this.bannedUserAgentsPercentage = PERCENTAGE_VALUES.find(value =>
      bannedUserAgentsPercentage ? value.id === bannedUserAgentsPercentage : true
    );
    this.suspiciousUserAgentsPercentage = PERCENTAGE_VALUES.find(value =>
      suspiciousUserAgentsPercentage ? value.id === suspiciousUserAgentsPercentage : true
    );
    this.bannedUserAgents = bannedUserAgents;
    this.suspiciousUserAgents = suspiciousUserAgents;
  }

  payload() {
    return {
      bannedUserAgentsPercentage: this.bannedUserAgentsPercentage?.id || 0,
      suspiciousUserAgentsPercentage: this.suspiciousUserAgentsPercentage?.id || 0,
      bannedUserAgents: this.bannedUserAgents,
      suspiciousUserAgents: this.suspiciousUserAgents,
    };
  }
}
