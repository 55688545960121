import RawData from './RawData';

/**
 * InfoEvent
 *
 * @class
 * @public
 */
export default class InfoEvent {
  constructor(id, status, impressions = null, clicks = null, conversions = null, postEvents = null) {
    this.id = id;
    this.status = status;
    this.impressions = impressions;
    this.clicks = clicks;
    this.conversions = conversions;
    this.postEvents = postEvents;
  }

  static create(event) {
    const attr = event.attributes;
    const status = attr.status || null;
    const impressions = attr.impressions?.length ? RawData.create(attr.impressions[0]) : null;
    const clicks = attr.clicks?.length ? RawData.create(attr.clicks[0]) : null;
    const conversions = attr.conversions?.length ? RawData.create(attr.conversions[0]) : null;
    const postevents = attr.postEvents?.length ? RawData.create(attr.postEvents[0]) : null;
    return new InfoEvent(event.id, status, impressions, clicks, conversions, postevents);
  }
}
