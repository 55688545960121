import IncludeItem from '@/model/shared/IncludeItem';

/**
 *  Referer
 *
 * @class Referer
 * @public
 * @constructor
 */
export default class Referer {
  /**
   * @typedef {object} Referer
   * @property @param {boolean} traficIDFA
   * @property @param {boolean} traficDomainBundle
   * @property @param {boolean} traficPublisher
   * @property @param {boolean} blockTraficApp
   * @property @param {IncludeItem} sourcesFilter
   *
   */
  constructor(
    traficIDFA = false,
    traficDomainBundle = false,
    traficPublisher = false,
    blockTraficApp = false,
    sourcesFilter = null
  ) {
    this.traficIDFA = traficIDFA;
    this.traficDomainBundle = traficDomainBundle;
    this.traficPublisher = traficPublisher;
    this.blockTraficApp = blockTraficApp;
    const typeSourcesFilter =
      sourcesFilter?.value?.length > 0 ? IncludeItem.VALID_TYPES.INCLUDE : IncludeItem.VALID_TYPES.NONE;
    const values = sourcesFilter?.value?.map(value => ({ id: value, name: value })) || [];
    this.sourcesFilter = new IncludeItem('sourcesFilter', 'Sources', typeSourcesFilter, values);
  }

  clone() {
    const sourcesFilter = {
      value: this.sourcesFilter.value.map(value => value.id),
    };
    return new Referer(
      this.traficIDFA,
      this.traficDomainBundle,
      this.traficPublisher,
      this.blockTraficApp,
      sourcesFilter
    );
  }

  payload() {
    const { traficIDFA, traficDomainBundle, traficPublisher, blockTraficApp } = this;

    return {
      traficIDFA: !!traficIDFA,
      traficDomainBundle: !!traficDomainBundle,
      traficPublisher: !!traficPublisher,
      blockTraficApp: !!blockTraficApp,
      includesSources: this.sourcesFilter.payload(),
    };
  }
}
