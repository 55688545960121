/**
 *  Tag
 *
 * @class Tag
 * @public
 * @constructor
 */

export default class Tag {
  /**
   * @typedef {object} Tag
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} createdAt
   * @property @param {string} updatedAt
   */
  constructor(id = null, name = '', createdAt = null, updatedAt = null) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  payload() {
    const { ...params } = this;

    return {
      ...params,
    };
  }

  /**
   *
   * @param entity
   * @param included
   * @return {Tag}
   */
  static create(entity) {
    return new Tag(entity.id, entity.attributes.name, entity.attributes.createdAt, entity.attributes.updatedAt);
  }
}
