import { PUBLISHER_STATUS, PUBLISHER_STATUS_DEFAULT } from '@/model/modules/ecommerce/publisher/PublisherStatus';
import { COMMISSION_TYPES } from '@/model/modules/ecommerce/publisher/CommissionTypes';
import { COMMISSION_OWNERS } from '@/model/modules/ecommerce/publisher/CommissionOwners';

/**
 * Publisher
 *
 * @class
 * @public
 * @constructor
 */

export default class Publisher {
  constructor(
    id = null,
    name = '',
    status = null,
    commissionValue = null,
    commissionType = COMMISSION_TYPES.PERCENT,
    commissionOwner = COMMISSION_OWNERS.OUR,
    category = null,
    subcategory = null,
    postBackUrl = null,
    noRestrictions = false,
    clientIds = null,
    techFee = null,
    navisionId = null,
    taxId = null
  ) {
    this.id = id;
    this.name = name;
    this.status = status || PUBLISHER_STATUS_DEFAULT;

    this.commissionType = commissionType;
    this.commissionValue = commissionValue;
    this.commissionOwner = commissionOwner;

    this.category = category;
    this.subcategory = subcategory;
    this.postBackUrl = postBackUrl;
    this.noRestrictions = noRestrictions;
    this.clientIds = clientIds;
    this.techFee = techFee;
    this.navisionId = navisionId;
    this.taxId = taxId;
  }

  payload() {
    const { subcategory, status, ...params } = this;

    return {
      ...params,
      category: subcategory?.id,
      status: status?.value || status,
    };
  }

  techFeePayload(clientId) {
    return {
      techFee: this.techFee / 100,
      clientId,
    };
  }

  static create(entity, included) {
    let category = entity.attributes.category;
    let subCategory = null;

    const splitCategory = category.split('-');

    if (splitCategory?.length) {
      category = included?.categories?.find(c => c.id === splitCategory[0]);
      subCategory = included?.subcategories?.find(c => c.id === entity.attributes.category);
    }

    const publisherStatus = PUBLISHER_STATUS.find(status => status.id === entity.attributes.status);

    const publisher = new Publisher(
      entity.id,
      entity.attributes.name,
      publisherStatus,
      entity.attributes.commissionValue,
      entity.attributes.commissionType,
      entity.attributes.commissionOwner,
      category,
      subCategory,
      entity.attributes.postBackUrl,
      entity.attributes.noRestrictions,
      entity.attributes.clientsIds || null,
      null,
      entity.attributes.navisionId,
      entity.attributes.taxId
    );

    const techFee = entity.attributes.techFee;
    const finalTechFee = techFee ? techFee * 100 : null;

    if (finalTechFee) {
      publisher.techFee = finalTechFee;
    }

    return publisher;
  }
}
