import { CATEGORY_RESOURCE, SUBCATEGORIES_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';

/**
 * Advertiser
 * @class
 * @constructor
 * @public
 */
export default class Advertiser {
  /**
   * Create a Advertiser
   * @param {string|null} id
   * @param {string} name
   * @param {number|null} offersCount
   * @param {number|null} activeOffersCount
   * @param {Category|null} category
   * @param {Category|null} subcategory
   * @param {Client} client
   */
  constructor(
    id = null,
    name = '',
    offersCount = null,
    activeOffersCount = null,
    category = null,
    subcategory = null,
    client = null
  ) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.subcategory = subcategory;
    this.offersCount = offersCount;
    this.activeOffersCount = activeOffersCount;
    this.client = client;
  }

  payload() {
    const { subcategory, client, ...params } = this;

    return {
      ...params,
      clientId: client?.id,
      category: subcategory?.id,
    };
  }

  /**
   *
   * @param {{attributes:Advertiser,relationships:Object}} entity
   * @param {Object}  included
   * @return {Tag}
   */
  static create(entity, included) {
    const category = included?.[CATEGORY_RESOURCE]?.find(cat => cat.id === entity.relationships?.category.data.id);
    const subCategory = included?.[SUBCATEGORIES_RESOURCE]?.find(
      subCat => subCat.id === entity.relationships?.subcategory.data.id
    );

    return new Advertiser(
      entity.id,
      entity.attributes.name,
      entity.attributes.offersCount,
      entity.attributes.activeOffersCount,
      category,
      subCategory,
      included?.[CLIENT_RESOURCE]?.[0]
    );
  }
}
