/**
 *  IpControl
 *
 * @class IpControl
 * @public
 * @constructor
 */

export default class IpControl {
  /**
   * @typedef {object} IpControl
   * @property @param {boolean} repeatedIps
   * @property @param {number} maxLeadsPerIP
   */
  constructor(repeatedIps = false, maxLeadsPerIP = 0, blockRepeatedIps = false) {
    this.repeatedIps = repeatedIps;
    this.maxLeadsPerIP = maxLeadsPerIP;
    this.blockRepeatedIps = blockRepeatedIps;
  }

  clone() {
    return new IpControl(this.repeatedIps, this.maxLeadsPerIP, this.blockRepeatedIps);
  }

  payload() {
    return {
      ...this,
      repeatedIps: !!this.repeatedIps,
      blockRepeatedIps: !!this.blockRepeatedIps,
    };
  }

  /**
   *
   * @param entity
   * @param included
   * @return {IpControl}
   */
  static create(entity) {
    return new IpControl(entity.id);
  }
}
