/**
 *  Capping
 *
 * @class
 * @public
 * @constructor
 */

export default class Capping {
  /**
   * @param {number} daily
   * @param {boolean} progressiveDaily
   * @param {number} week
   * @param {boolean} progressiveWeek
   * @param {number} month
   * @param {boolean} progressiveMonth
   * @param {number} total
   * @param {boolean} progressiveTotal
   */
  constructor(
    daily = null,
    progressiveDaily = false,
    week = null,
    progressiveWeek = false,
    month = null,
    progressiveMonth = false,
    total = null,
    progressiveTotal = false
  ) {
    this.daily = daily;
    this.progressiveDaily = progressiveDaily;
    this.week = week;
    this.progressiveWeek = progressiveWeek;
    this.month = month;
    this.progressiveMonth = progressiveMonth;
    this.total = total;
    this.progressiveTotal = progressiveTotal;
  }
}
