/**
 *  PostEvent
 *
 * @class
 * @public
 * @constructor
 */

export default class PostEvent {
  /**
   * @param {string} id
   * @param {boolean} unique
   * @param {{value: number}} netPrice
   * @param {{value: number}} grossPrice
   * @param {string} pixel - pixel or code
   * @param {string} templateId
   */
  constructor(id = null, unique = null, netPrice = null, grossPrice = null, pixel = null, templateId = null) {
    this.id = id;
    this.unique = unique;
    this.netPrice = netPrice;
    this.grossPrice = grossPrice;
    this.pixel = pixel; // TODO old value
    this.code = pixel;
    this.templateId = templateId;
  }

  payload() {
    const { templateId, unique, netPrice, grossPrice } = this;
    return {
      unique,
      netPrice: netPrice?.value,
      grossPrice: grossPrice?.value,
      postEventId: templateId,
    };
  }

  /**
   * @param {{id:string,attributes:PostEvent}} entity
   * @return {PostEvent}
   */
  static create({ id, attributes }) {
    return new PostEvent(
      id,
      attributes.unique,
      attributes.netPrice,
      attributes.grossPrice,
      attributes.pixel || attributes.code,
      attributes.postEventId
    );
  }
}
