/**
 *  ControlLanguage
 *
 * @class ControlLanguage
 * @public
 * @constructor
 */
export default class ControlLanguage {
  /**
   * @typedef {object} ControlLanguage
   * @property @param {boolean} blockWrongLanguage
   */
  constructor(blockWrongLanguage = false) {
    this.blockWrongLanguage = blockWrongLanguage;
  }

  clone() {
    return new ControlLanguage(this.blockWrongLanguage);
  }

  payload() {
    return {
      blockWrongLanguage: this.blockWrongLanguage,
    };
  }

  /**
   *
   * @param entity
   * @param included
   * @return {ControlLanguage}
   */
  static create(entity) {
    return new ControlLanguage(entity.id);
  }
}
