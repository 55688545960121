export default class Event {
  constructor(id = null, eventName = null, user = null, occurredOn = null) {
    this.id = id;
    this.eventName = eventName;
    this.user = user;
    this.occurredOn = occurredOn;
  }

  static create(entity, included) {
    let historyAuthor;
    if (included?.users) {
      historyAuthor = included.users.find(author => author.id === entity.attributes.authorId);
    }
    return new Event(
      entity.attributes.eventId,
      entity.attributes.domainEventName,
      historyAuthor,
      entity.attributes.occurredOn
    );
  }
}
