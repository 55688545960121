import IncludeItem from '@/model/shared/IncludeItem';
import ASNItem from './ASNItem';

/**
 *  ASN
 *
 * @class ASN
 * @public
 * @constructor
 */
export default class ASN {
  /**
   * @typedef {object} ASN
   * @property @param {IncludeItem} asnFilter
   */
  constructor(includes = [], excludes = []) {
    let typeASNFilter = IncludeItem.VALID_TYPES.NONE;
    let array = [];
    if (includes?.length > 0) {
      typeASNFilter = IncludeItem.VALID_TYPES.INCLUDE;
      array = includes.map(include => {
        if (typeof include === 'string') return new ASNItem(include);
        return new ASNItem(
          include.id,
          include.name,
          include.autonomousSystemNumber,
          include.autonomousSystemOrganization
        );
      });
    } else if (excludes?.length > 0) {
      typeASNFilter = IncludeItem.VALID_TYPES.EXCLUDE;
      array = excludes.map(exclude => {
        if (typeof exclude === 'string') return new ASNItem(exclude);
        return new ASNItem(
          exclude.id,
          exclude.name,
          exclude.autonomousSystemNumber,
          exclude.autonomousSystemOrganization
        );
      });
    }
    this.asnFilter = new IncludeItem('asnFilter', 'ASN', typeASNFilter, array);
  }

  clone() {
    const includes = this.asnFilter.type === IncludeItem.VALID_TYPES.INCLUDE ? this.asnFilter.value : [];
    const excludes = this.asnFilter.type === IncludeItem.VALID_TYPES.EXCLUDE ? this.asnFilter.value : [];
    return new ASN(includes, excludes);
  }

  payload() {
    const params = {
      includes: [],
      excludes: [],
    };
    switch (this.asnFilter.type) {
      case IncludeItem.VALID_TYPES.INCLUDE:
        params.includes = this.asnFilter.payload();
        break;
      case IncludeItem.VALID_TYPES.EXCLUDE:
        params.excludes = this.asnFilter.payload();
        break;
    }

    return params;
  }
}
