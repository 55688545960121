import Fraud from './Fraud';
import OfferAttribution from '@/entities/ecommerce/OfferAttribution';
import IpControl from './IpControl';
import ASN from './ASN';
import Referer from './Referer';
import UsageType from './UsageType';
import ControlApps from './ControlApps';
import ControlUserAgents from './ControlUserAgents';
import ProxyUsageType from './ProxyUsageType';
import IpMissMatch from './IpMissMatch';
import ControlLanguage from './ControlLanguage';
import { IP_USAGE_TYPES } from '@/components/organisms/modules/ecommerce/offer/form/fraud/config';

export default class FraudTemplate extends Fraud {
  /**
   * @typedef {object} FraudTemplate
   * @property @param {string} id
   * @property @param {IpControl} ipControl
   * @property @param {ASN} asn
   * @property @param {Referer} referer
   * @property @param {ControlApps} controlApps
   * @property @param {ControlUserAgents} controlUserAgents
   * @property @param {ProxyUsageType} proxyUsageType
   * @property @param {IpMissMatch} ipMissMatch
   * @property @param {ControlLanguage} controlLanguage
   * @property @param {string} name
   * @property @param {number} uniqueConversion
   * @property @param {number} uniqueUserDailyCapping
   * @property @param {OfferAttribution} offerAttribution
   * @property @param {number} countOffers
   * @property @param {Date} createdAt
   */
  constructor(
    id = null,
    ipControl = new IpControl(),
    asn = new ASN(),
    referer = new Referer(),
    usageType = new UsageType(),
    controlApps = new ControlApps(),
    controlUserAgents = new ControlUserAgents(),
    proxyUsageType = new ProxyUsageType(),
    ipMissMatch = new IpMissMatch(),
    controlLanguage = new ControlLanguage(),
    name = null,
    uniqueConversion = 0,
    uniqueUserDailyCapping = 0,
    offerAttribution = new OfferAttribution(),
    countOffers = null,
    createdAt = null
  ) {
    super(
      id,
      ipControl,
      asn,
      referer,
      usageType,
      controlApps,
      controlUserAgents,
      proxyUsageType,
      ipMissMatch,
      controlLanguage
    );
    this.name = name;
    this.uniqueConversion = uniqueConversion;
    this.uniqueUserDailyCapping = uniqueUserDailyCapping;
    this.offerAttribution = offerAttribution;
    this.countOffers = countOffers;
    this.createdAt = createdAt;
  }

  static create(entity, included = {}) {
    const allAsn = included?.asn;
    let attr = entity.attributes.config;
    if (!attr || attr.length === 0) {
      attr = new FraudTemplate();
    }
    const { usageType, proxyUsageType, ipMissMatch, controlLanguage } = attr;
    const utClick = IP_USAGE_TYPES.find(type => type.id === usageType.click);
    const utLeadClick = IP_USAGE_TYPES.find(type => type.id === usageType.lead_click);
    const utLeadConversion = IP_USAGE_TYPES.find(type => type.id === usageType.lead_conversion);
    const { repeatedIps, maxLeadsPerIP, blockRepeatedIps } = attr.ipControl;
    let { includes, excludes } = attr.asn;
    if (allAsn && includes) {
      includes = includes.map(include => {
        return allAsn.find(asn => asn.id === include);
      });
    }
    if (allAsn && excludes) {
      excludes = excludes.map(exclude => {
        return allAsn.find(asn => asn.id === exclude);
      });
    }

    const { traficIDFA, traficDomainBundle, traficPublisher, blockTraficApp, includesSources } = attr.referer;
    const { bannedAppsPercentage, suspiciousAppsPercentage, bannedApps, suspiciousApps } = Array.isArray(
      attr.controlApps
    )
      ? new ControlApps()
      : attr.controlApps;
    const { bannedUserAgentsPercentage, suspiciousUserAgentsPercentage, bannedUserAgents, suspiciousUserAgents } =
      Array.isArray(attr.controlUserAgents) ? new ControlUserAgents() : attr.controlUserAgents;
    const createdAt = new Date(entity.attributes.createdAt);
    const offerAttribution = attr.offerAttribution || new OfferAttribution();
    return new FraudTemplate(
      entity.id,
      new IpControl(repeatedIps, maxLeadsPerIP, blockRepeatedIps),
      new ASN(includes, excludes),
      new Referer(traficIDFA, traficDomainBundle, traficPublisher, blockTraficApp, includesSources),
      new UsageType(utClick, utLeadClick, utLeadConversion),
      new ControlApps(bannedAppsPercentage, suspiciousAppsPercentage, bannedApps, suspiciousApps),
      new ControlUserAgents(
        bannedUserAgentsPercentage,
        suspiciousUserAgentsPercentage,
        bannedUserAgents,
        suspiciousUserAgents
      ),
      new ProxyUsageType(proxyUsageType.lead_click, proxyUsageType.lead_conversion),
      new IpMissMatch(ipMissMatch),
      new ControlLanguage(controlLanguage.blockWrongLanguage),
      entity.attributes.name,
      attr.uniqueConversion || 0,
      attr.uniqueUserDailyCapping,
      offerAttribution,
      entity.attributes.countOffers,
      createdAt
    );
  }

  payload() {
    const {
      name,
      ipControl,
      asn,
      referer,
      usageType,
      controlApps,
      controlUserAgents,
      proxyUsageType,
      ipMissMatch,
      controlLanguage,
      uniqueConversion,
      uniqueUserDailyCapping,
      offerAttribution,
    } = this;

    return {
      name,
      config: {
        ipControl: ipControl.payload(),
        asn: asn.payload(),
        referer: referer.payload(),
        usageType: usageType.payload(),
        controlApps: controlApps.payload(),
        controlUserAgents: controlUserAgents.payload(),
        proxyUsageType: proxyUsageType.payload(),
        ipMissMatch: ipMissMatch.payload(),
        controlLanguage: controlLanguage.payload(),
        uniqueConversion,
        uniqueUserDailyCapping,
        offerAttribution,
      },
    };
  }
}
