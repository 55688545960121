import Creative from '@/model/modules/ecommerce/offer/creative/Creative';
import CreativeTypes from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
/**
 *  CreativeFeed
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeFeed extends Creative {
  /**
   * @param {String} id
   * @param {String} name
   * @param {Publisher} publisher
   * @param {Array} callbackUrl
   * @param {String} status
   * @param {String} url
   */

  constructor(id = null, name = null, publisher = null, feedId = null) {
    super(id, CreativeTypes.FEED, publisher);
    this.name = name;
    this.feedId = feedId;
  }

  /**
   *
   * @param entity
   * @return {CreativeFeed}
   */
  static create(entity) {
    const creative = entity.attributes;
    return new CreativeFeed(entity.id, creative.name, null, creative.feedId);
  }

  payload() {
    return {
      name: this.name,
      feedId: this.feedId,
      // callbackUrl: this.callbackUrl,
    };
  }

  clone() {
    return new CreativeFeed(this.id, this.name, this.publisher, this.feedId);
  }
}
