export default class OfferAttribution {
  constructor(
    postclick_active = true,
    postclick_value = 0,
    postview_active = false,
    postview_value = 0,
    minAttributionTime = 0,
    maxAttributionTime = 0
  ) {
    this.postclick_active = postclick_active;
    this.postclick_value = postclick_value;
    this.postview_active = postview_active;
    this.postview_value = postview_value;
    this.minAttributionTime = minAttributionTime;
    this.maxAttributionTime = maxAttributionTime;
  }

  static create(entity) {
    return new OfferAttribution(
      entity.postclick_active,
      entity.postclick_value,
      entity.postview_active,
      entity.postview_value,
      entity.minAttributionTime,
      entity.maxAttributionTime
    );
  }
}
