export default class CountBlockedReasons {
  constructor(total = null, impressions = [], clicks = [], conversions = [], postEvents = []) {
    this.total = total;
    this.impressions = impressions;
    this.clicks = clicks;
    this.conversions = conversions;
    this.postEvents = postEvents;
  }

  static create(entity) {
    let total = 0;
    const blockedTypes = entity.attributes;
    Object.keys(blockedTypes).forEach(key => {
      blockedTypes[key].forEach(blockType => {
        total += blockType.total;
      });
    });
    return new CountBlockedReasons(
      total,
      blockedTypes.impressions,
      blockedTypes.clicks,
      blockedTypes.conversions,
      blockedTypes.postEvents
    );
  }
}
