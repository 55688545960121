import Creative from '@/model/modules/ecommerce/offer/creative/Creative';
import CreativeTypes from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import CreativeHtmlSizes from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtmlSizes';

/**
 *  CreativeCreativeHtml
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeHtml extends Creative {
  /**
   * @param {String} id
   * @param {Publisher} publisher
   * @param {CreativeHtmlSize} size
   * @param {String} name
   * @param {String} code
   * @param {Boolean} keepHtmlLinks
   * @param {String} externalCode
   */

  constructor(
    id = null,
    publisher = null,
    size = null,
    name = null,
    code = '',
    keepHtmlLinks = false,
    externalCode = ''
  ) {
    super(id, CreativeTypes.HTML, publisher);
    this.size = size;
    this.name = name;
    this.code = code;
    this.keepHtmlLinks = keepHtmlLinks;
    this.externalCode = externalCode;
  }

  clone() {
    return new CreativeHtml(
      this.id,
      this.publisher,
      this.size,
      this.name,
      this.code,
      this.keepHtmlLinks,
      this.externalCode
    );
  }

  payload() {
    const parent = super.payload();
    const { size, name, code, keepHtmlLinks, externalCode } = this;
    const child = { size: size.id, name, code, keepHtmlLinks, externalCode };
    return { ...parent, ...child };
  }

  /**
   *
   * @param entity
   * @return {CreativeHtml}
   */
  static create(entity) {
    const creative = entity.attributes;
    const size = CreativeHtmlSizes[creative.size];
    return new CreativeHtml(
      entity.id,
      creative.publisher,
      size,
      creative.name,
      creative.code,
      creative.keepHtmlLinks,
      creative.externalCode
    );
  }
}
