/**
 * RawData
 *
 * @class
 * @public
 */
export default class RawData {
  constructor({
    __time = null,
    event_session_id = null,
    unique_user_id = null,
    time = null,
    date_click = null,
    date_lead = null,
    ctit_seconds = null,
    cl = null,
    host = null,
    origin = null,
    advertiserName = null,
    ad = null,
    campaignName = null,
    camp = null,
    offerName = null,
    of = null,
    offerCountryIso = null,
    cr = null,
    cr_type = null,
    publisherName = null,
    pb = null,
    sid = null,
    afc = null,
    bundle = null,
    lang = null,
    ip = null,
    countryName = null,
    countryCode = null,
    regionName = null,
    cityName = null,
    coordinate = null,
    coord_latitude = null,
    coord_longitude = null,
    carrier = null,
    usage_type = null,
    domainName = null,
    isp = null,
    proxy_ip = null,
    proxy_country = null,
    proxy_carrier = null,
    proxy_usage_type = null,
    proxy_domain_name = null,
    user_agent = null,
    node = null,
    device_brand = null,
    device = null,
    postEventName = null,
    evid = null,
    browser = null,
    browser_model = null,
    browser_full = null,
    os = null,
    os_model = null,
    os_full = null,
    rf = null,
    tl = null,
    url = null,
    ev = null,
    ru = null,
    tr = null,
    validation_id = null,
    validation_description = null,
    validation_type = null,
    occurred_on_postback_url = null,
    postback_url = null,
    postback_url_final = null,
    status_code_postback_url = null,
    response_postback_url = null,
    timing_total_postback_url = null,
    event_revenue = null,
    event_currency = null,
    pub_revenue = null,
    revenue = null,
    commission = null,
    pub_commission = null,
    evv = null,
    error_description = null,
    error_id = null,
    error_type = null,
    version = null,
    track = null,
    avid = null,
  } = {}) {
    this.__time = __time;
    this.event_session_id = event_session_id;
    this.unique_user_id = unique_user_id;
    this.time = time;
    this.date_click = date_click;
    this.date_lead = date_lead;
    this.ctit_seconds = ctit_seconds;
    this.cl = cl;
    this.host = host;
    this.origin = origin;
    this.advertiserName = advertiserName;
    this.ad = ad;
    this.campaignName = campaignName;
    this.camp = camp;
    this.offerName = offerName;
    this.of = of;
    this.offerCountryIso = offerCountryIso;
    this.cr = cr;
    this.cr_type = cr_type;
    this.publisherName = publisherName;
    this.pb = pb;
    this.sid = sid;
    this.afc = afc;
    this.bundle = bundle;
    this.lang = lang;
    this.ip = ip;
    this.countryName = countryName;
    this.countryCode = countryCode;
    this.regionName = regionName;
    this.cityName = cityName;
    this.coordinate = coordinate;
    this.coord_latitude = coord_latitude;
    this.coord_longitude = coord_longitude;
    this.carrier = carrier;
    this.usage_type = usage_type;
    this.domainName = domainName;
    this.isp = isp;
    this.proxy_ip = proxy_ip;
    this.proxy_country = proxy_country;
    this.proxy_carrier = proxy_carrier;
    this.proxy_usage_type = proxy_usage_type;
    this.proxy_domain_name = proxy_domain_name;
    this.user_agent = user_agent;
    this.node = node;
    this.device_brand = device_brand;
    this.device = device;
    this.postEventName = postEventName;
    this.evid = evid;
    this.browser = browser;
    this.browser_model = browser_model;
    this.browser_full = browser_full;
    this.os = os;
    this.os_model = os_model;
    this.os_full = os_full;
    this.rf = rf;
    this.tl = tl;
    this.url = url;
    this.ev = ev;
    this.ru = ru;
    this.tr = tr;
    this.validation_id = validation_id;
    this.validation_description = validation_description;
    this.validation_type = validation_type;
    this.occurred_on_postback_url = occurred_on_postback_url;
    this.postback_url = postback_url;
    this.postback_url_final = postback_url_final;
    this.status_code_postback_url = status_code_postback_url;
    this.response_postback_url = response_postback_url;
    this.timing_total_postback_url = timing_total_postback_url;
    this.event_revenue = event_revenue;
    this.event_currency = event_currency;
    this.pub_revenue = pub_revenue;
    this.revenue = revenue;
    this.commission = commission;
    this.pub_commission = pub_commission;
    this.evv = evv;
    this.error_description = error_description;
    this.error_id = error_id;
    this.error_type = error_type;
    this.version = version;
    this.track = track;
    this.avid = avid;
  }

  /**
   * @param {Object} data
   * @returns RawData
   */
  static create(data) {
    return new RawData(data);
  }
}
