/**
 * UsageType
 *
 * @class UsageType
 * @public
 * @constructor
 */
import { IP_USAGE_TYPES } from '@/components/organisms/modules/ecommerce/offer/form/fraud/config';

export default class UsageType {
  /**
   * @typedef {object} UsageType
   * @property @param {object} click
   * @property @param {object} lead_click
   * @property @param {object} lead_conversion
   */
  constructor(click = IP_USAGE_TYPES[0], lead_click = IP_USAGE_TYPES[0], lead_conversion = IP_USAGE_TYPES[0]) {
    this.click = click;
    this.lead_click = lead_click;
    this.lead_conversion = lead_conversion;
  }

  clone() {
    return new UsageType(this.click, this.lead_click, this.lead_conversion);
  }

  payload() {
    return {
      click: this.click.id,
      lead_click: this.lead_click.id,
      lead_conversion: this.lead_conversion.id,
    };
  }
}
