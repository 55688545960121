import SelectOption from '@/model/shared/SelectOption';

export const PROXY_USAGE_TYPE_OPTIONS = {
  NONE: new SelectOption('NONE', 'None'),
  ALL_EXCEPT_VPN_TOR_DCH_SES_RES: new SelectOption('ALL_EXCEPT_VPN_TOR_DCH_SES_RES', 'All but VPN, TOR, DCH, SES, RES'),
  NOT_PROXIES: new SelectOption('NOT_PROXIES', 'No proxies at all'),
};
/**
 *  ProxyUsageType
 *
 * @class ProxyUsageType
 * @public
 * @constructor
 */
export default class ProxyUsageType {
  /**
   * @typedef {object} ProxyUsageType
   * @property @param {object} leadClick
   * @property @param {object} leadConversion
   */
  constructor(leadClick = PROXY_USAGE_TYPE_OPTIONS.NONE.id, leadConversion = PROXY_USAGE_TYPE_OPTIONS.NONE.id) {
    this.leadClick = PROXY_USAGE_TYPE_OPTIONS[leadClick];
    this.leadConversion = PROXY_USAGE_TYPE_OPTIONS[leadConversion];
  }

  payload() {
    return {
      lead_click: this.leadClick.id,
      lead_conversion: this.leadConversion.id,
    };
  }
}
