// @ts-check
import ValidAndInvalidObject from '@/model/modules/ecommerce/analytics/ValidAndInvalidObject.js';

/**
 *
 * @param {string} propertyBaseName  - The base name of the property.
 * @param {Analytics} analytics  - The analytics object.
 * @returns Conversiones
 */
function buildValidInvalidObject(propertyBaseName, analytics) {
  return new ValidAndInvalidObject(
    analytics[`${propertyBaseName}Accumulated`],
    analytics[propertyBaseName],
    analytics[`${propertyBaseName}Blocked`]
  );
}

/**
 * Analytics
 *
 * @class
 * @public
 */
export default class Analytics {
  /**
   * @constructor
   * @param {string} id
   * @param {string} breakdown
   * @param {string} breakdownId
   * @param {string} subBreakdown
   * @param {buildValidInvalidObject|object} [clicks={}]
   * @param {buildValidInvalidObject|object} [conversions={}]
   * @param {buildValidInvalidObject|object} [orderValue={}]
   * @param {buildValidInvalidObject|object} [leads={}]
   * @param {buildValidInvalidObject|object} [impressions={}]
   * @param {number} revenue
   * @param {number} expense
   * @param {number} profit
   * @param {number} cr
   * @param {string} campaignName
   * @param {Object} postEvents
   * @param {string} subBreakdownId
   * @param {number} crRate
   * @param {string} url
   * @param {string} category
   * @param {string} publisherName
   */
  constructor(
    id = null,
    breakdown = null,
    breakdownId = null,
    subBreakdown = null,
    clicks = {},
    conversions = {},
    orderValue = {},
    leads = {},
    impressions = {},
    revenue = 0,
    expense = 0,
    profit = 0,
    cr = 0,
    advertiserId = null,
    advertiserName = null,
    campaignName = null,
    postEvents = null,
    subBreakdownId = null,
    crRate = 0,
    grossRevenue = null,
    url = null,
    category = null,
    publisherName = null
  ) {
    this.id = id;
    this.breakdown = breakdown;
    this.breakdownId = breakdownId;
    this.subBreakdown = subBreakdown;
    this.revenue = revenue;
    this.expense = expense;
    this.profit = profit;
    this.cr = cr;
    this.advertiserId = advertiserId;
    this.advertiserName = advertiserName;
    this.campaignName = campaignName;
    this.campaignIsAutogenerated = !!campaignName && campaignName.includes('(Autogenerated)');
    this.postEvents = postEvents;
    this.subBreakdownId = subBreakdownId;

    this.clicks = clicks;
    this.conversions = conversions;
    this.impressions = impressions;
    this.leads = leads;
    this.orderValue = orderValue;
    this.crRate = crRate;
    this.grossRevenue = grossRevenue;
    this.url = url;
    this.category = category;
    this.publisherName = publisherName;
  }

  /**
   *
   * @param {object} entity
   * @return {Analytics}
   */
  static create(entity) {
    const analytics = entity.attributes.analytic;

    return new Analytics(
      entity.id,
      analytics.breakdown,
      analytics.breakdownId,
      analytics.subBreakdown,
      buildValidInvalidObject('clicks', analytics),
      buildValidInvalidObject('conversions', analytics),
      buildValidInvalidObject('orderValue', analytics),
      buildValidInvalidObject('leads', analytics),
      buildValidInvalidObject('impressions', analytics),
      analytics.revenue,
      analytics.expense,
      analytics.profit,
      analytics.cr,
      analytics.advertiserId,
      analytics.advertiserName,
      analytics.campaignName,
      analytics.postEvents,
      analytics.subBreakdownId,
      analytics.crRate,
      analytics.grossRevenue,
      analytics.url,
      analytics.category,
      analytics.publisherName
    );
  }
}
