export default class BlockedElement {
  /**
   *
   * @property @param {String} id
   * @property @param {String} name
   * @property @param {String} type
   */
  constructor(id = null, name = '', type = null) {
    this.id = id;
    this.name = name;
    this.type = type;
  }

  payload() {
    const { name, type } = this;
    return {
      name,
      type,
    };
  }

  static create(entity) {
    const attributes = entity.attributes;
    return new BlockedElement(entity.id, attributes.name, attributes.type);
  }
}
