import { CONTEXTS } from '@/model/shared/contexts';
import { ClientShared } from '../shared/ClientShared';
import { Address } from '../shared/Address';

export class EcommerceClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    address = new Address(),
    techFee = null,
    faviconImage = null,
    faviconPath = null,
    faviconUrl = null,
    backgroundDarkImage = null,
    backgroundDarkPath = null,
    backgroundDarkUrl = null,
    backgroundLightImage = null,
    backgroundLightPath = null,
    backgroundLightUrl = null
  ) {
    super(id, name, email, CONTEXTS.ECOMMERCE.id, contactName, isExternal, users, taxId);
    this.address = address;
    this.techFee = techFee;
    this.faviconImage = faviconImage;
    this.faviconPath = faviconPath;
    this.faviconUrl = faviconUrl;
    this.backgroundDarkImage = backgroundDarkImage;
    this.backgroundDarkPath = backgroundDarkPath;
    this.backgroundDarkUrl = backgroundDarkUrl;
    this.backgroundLightImage = backgroundLightImage;
    this.backgroundLightPath = backgroundLightPath;
    this.backgroundLightUrl = backgroundLightUrl;
  }

  payload() {
    const { users, ...params } = this;
    const address = this.address?.payload?.() || this.address;
    const formData = new FormData();
    Object.entries(address).forEach(entity => {
      const [key, value] = entity;
      formData.append(`address[${key}]`, value);
    });
    formData.append('techFee', this.techFee / 100);
    if (users.length === 0) {
      formData.append('userIds[]', []);
    } else {
      users.forEach(user => {
        formData.append('userIds[]', user.id);
      });
    }

    formData.append('backgroundDarkImage', params.backgroundDarkImage);
    formData.append('backgroundDarkPath', params.backgroundDarkPath);
    formData.append('backgroundLightImage', params.backgroundLightImage);
    formData.append('backgroundLightPath', params.backgroundLightPath);
    formData.append('faviconImage', params.faviconImage);
    formData.append('faviconPath', params.faviconPath);
    formData.append('contactName', params.contactName);
    formData.append('email', params.email);
    formData.append('id', params.id);
    formData.append('isExternal', params.isExternal);
    formData.append('name', params.name);
    formData.append('taxId', params.taxId);
    formData.append('type', params.type);

    return formData;
  }

  /**
   *
   * @param {{id:string, attributes: EcommerceClient}} entity
   * @param {Object} included
   * @return {EcommerceClient}
   */
  static create(entity, included) {
    const linkedUsers = entity.relationships?.linkedUsers.data;
    const users = linkedUsers && included?.users?.filter(user => linkedUsers.find(linkUser => linkUser.id === user.id));
    return new EcommerceClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      users,
      entity.attributes.taxId,
      Address.from(entity.attributes.address),
      (entity.attributes.techFee || 0) * 100,
      entity.attributes.faviconImage,
      entity.attributes.faviconPath,
      entity.attributes.faviconUrl,
      entity.attributes.backgroundDarkImage,
      entity.attributes.backgroundDarkPath,
      entity.attributes.backgroundDarkUrl,
      entity.attributes.backgroundLightImage,
      entity.attributes.backgroundLightPath,
      entity.attributes.backgroundLightUrl
    );
  }
}
