/**
 *  CreativeHtmlSize
 *
 * @class
 * @public
 * @constructor
 */

export default class CreativeHtmlSize {
  /**
   * @param {String} id
   * @param {String} name
   */

  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }
}
