import { stringToDate } from '@/filters/dateFilters';
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_DEFAULT } from '@/model/modules/ecommerce/campaign/CampaignStatus';
import * as RESOURCE from '@/services/keys';

export default class Campaign {
  /**
   * @typedef Campaign
   * @property @param {Client} client
   * @property @param {string} id
   * @property @param {string} name
   * @property @param {string} campaignId
   * @property @param {number} clicks
   * @property @param {string} status
   * @property @param {Date} createdAt
   * @property @param {number} totalLimit
   * @property @param {String} pixel
   */
  constructor(
    client = null,
    id = null,
    name = '',
    campaignId = null,
    clicks = null,
    status = null,
    createdAt = new Date(),
    totalLimit = null,
    pixel = null
  ) {
    this.id = id;
    this.name = name;
    this.campaignId = campaignId;
    this.clicks = clicks;
    this.status = status || CAMPAIGN_STATUS_DEFAULT;
    this.createdAt = createdAt;
    this.totalLimit = totalLimit;
    this.client = client;
    this.pixel = pixel;
  }

  payload() {
    const { name, status, campaignId, totalLimit, client, pixel } = this;

    return {
      name,
      number: campaignId,
      status: status.id,
      totalLimit: totalLimit || null,
      clientId: client?.id,
      pixel,
    };
  }

  /**
   *
   * @param {{id:string, attributes: Campaign}} entity
   * @param object included
   * @return {Campaign}
   */
  static create(entity, included) {
    const { name, number, clicks, createdAt, totalLimit, pixel } = entity.attributes;
    let client = null;
    if (included?.[RESOURCE.CLIENT_RESOURCE]) {
      client = included?.[RESOURCE.CLIENT_RESOURCE].find(client => client.id === entity.relationships.client?.data?.id);
    }
    const status = CAMPAIGN_STATUS.find(status => status.id === entity.attributes.status);

    return new Campaign(
      client,
      entity.id,
      name,
      number,
      clicks,
      status,
      createdAt ? stringToDate(createdAt) : null,
      totalLimit,
      pixel
    );
  }
}
