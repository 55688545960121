import * as RESOURCE from '@/services/keys';
import { EcommerceClient as Client } from './EcommerceClient';
import { EntityParser } from '@/entities/shared/EntityParser';
import Advertiser from '@/entities/ecommerce/Advertiser';
import Analytics from '@/entities/ecommerce/Analytics';
import ASNItem from '@/entities/ecommerce/fraud/ASNItem';
import BlockedElement from '@/entities/ecommerce/BlockedElement';
import Category from '@/entities/Core/Category';
import CommentShared from '@/model/shared/CommentShared';
import CreativeBanner from '@/model/modules/ecommerce/offer/creative/creativeBanner/CreativeBanner';
import CreativeDeeplink from '@/model/modules/ecommerce/offer/creative/creativeDeeplink/CreativeDeeplink';
import CreativeFeed from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeed';
import CreativeHtml from '@/model/modules/ecommerce/offer/creative/creativeHtml/CreativeHtml';
import CreativeTextlink from '@/model/modules/ecommerce/offer/creative/creativeTextlink/CreativeTextlink';
import Currency from '@/entities/shared/Currency';
import Event from '@/entities/ecommerce/Event';
import Fraud from '@/entities/ecommerce/fraud/Fraud';
import Offer from '@/entities/ecommerce/Offer';
import OfferPublisher from '@/entities/ecommerce/OfferPublisher';
import PostEvent from '@/entities/ecommerce/PostEvent';
import PostEventTemplate from '@/entities/ecommerce/PostEventTemplate';
import Publisher from './Publisher';
import Tag from '@/entities/ecommerce/Tag';
import User from '@/entities/shared/User';
import UserCore from '@/entities/Auth/UserCore';
import Campaign from './Campaign';
import Country from './Country';
import InfoEvent from './InfoEvent';
import InfoEventCollection from './InfoEventCollection';
import UrlAlias from './UrlAlias';
import CountBlockedReasons from './CountBlockedReasons';
import FraudTemplate from './fraud/FraudTemplate';
import Report from './ReportsData';
import ReportAuthor from './ReportAuthor';

const ecommerceEntityParser = new EntityParser({
  [RESOURCE.ADVERTISER_RESOURCE]: Advertiser.create,
  [RESOURCE.ANALYTICS_RESOURCE]: Analytics.create,
  [RESOURCE.ASN_RESOURCE]: ASNItem.create,
  [RESOURCE.AUTHOR_RESOURCE]: User.create,
  [RESOURCE.BLOCK_LIST_RESOURCE]: BlockedElement.create,
  [RESOURCE.CATEGORY_RESOURCE]: Category.create,
  [RESOURCE.CLIENT_RESOURCE]: Client.create,
  [RESOURCE.CREATIVE_BANNER_RESOURCE]: CreativeBanner.create,
  [RESOURCE.CREATIVE_DEEPLINK_RESOURCE]: CreativeDeeplink.create,
  [RESOURCE.CREATIVE_HTML_RESOURCE]: CreativeHtml.create,
  [RESOURCE.CREATIVE_TEXTLINK_RESOURCE]: CreativeTextlink.create,
  [RESOURCE.CREATIVE_FEED_RESOURCE]: CreativeFeed.create,
  [RESOURCE.CURRENCY_RESOURCE]: Currency.create,
  [RESOURCE.EVENTS_RESOURCE]: Event.create,
  [RESOURCE.OFFER_COMMENTS_RESOURCE]: CommentShared.create,
  [RESOURCE.OFFER_FRAUD_RESOURCE]: Fraud.create,
  [RESOURCE.OFFER_PUBLISHER_RESOURCE]: OfferPublisher.create,
  [RESOURCE.OFFER_RESOURCE]: Offer.create,
  [RESOURCE.POST_EVENT_RESOURCE]: PostEventTemplate.create,
  [RESOURCE.OFFER_POST_EVENT_RESOURCE]: PostEvent.create,
  [RESOURCE.PUBLISHER_RESOURCE]: Publisher.create,
  [RESOURCE.SUBCATEGORIES_RESOURCE]: Category.create,
  [RESOURCE.TAGS_RESOURCE]: Tag.create,
  [RESOURCE.USER_RESOURCE]: UserCore.create,
  [RESOURCE.CAMPAIGNS_RESOURCE]: Campaign.create,
  [RESOURCE.COUNTRIES_RESOURCE]: Country.create,
  [RESOURCE.INFO_EVENT_SESSION_ID]: InfoEvent.create,
  [RESOURCE.INFO_EVENT_SESSION_ID_COLLECTION]: InfoEventCollection.create,
  [RESOURCE.CLIENTS_ALIAS]: UrlAlias.create,
  [RESOURCE.BLOCKED_REASONS]: CountBlockedReasons.create,
  [RESOURCE.FRAUD_TEMPLATE_RESOURCE]: FraudTemplate.create,
  [RESOURCE.REPORTS_RESOURCE]: Report.create,
  [RESOURCE.AUTHORS_RESOURCE]: ReportAuthor.create,
  [RESOURCE.SINGLE_ASN_RESOURCE]: e => ({ id: e.id, ...e.attributes }),
  // TODO waiting for back to delete this
  template: e => ({ id: e.id, ...e.attributes }),
  blockLists: BlockedElement.create,
  offerFraud: Fraud.create,
});
export default ecommerceEntityParser;
