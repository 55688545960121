/**
 *  Creative
 *
 * @class
 * @public
 * @constructor
 */

export default class Creative {
  /**
   * @param {String} id
   * @param {CreativeType} type
   * @param {Publisher} publisher
   */

  constructor(id = null, type = null, publisher = null) {
    this.id = id;
    this.type = type;
    this.publisher = publisher;
  }

  payload() {
    return { id: this.id };
  }

  payloadPublisherLink() {
    return { publisherId: this.publisher.id };
  }
}
